.wrapper_slider_titulo
/**#d6d5c3 gris**/
/**#88b12d verde oscuro**/
/**#8bb02c verde claro**/

/**Parrafo 'Open Sans200sans-serif**/
/**Título, Menu 'Montserrat', sans-serif**/
/**Botones 'Raleway', sans-serif**/

.izq{
    padding-left: 0;
}

.der{
    padding-right: 0;
}

.row{
    margin-right: 0;
    margin-left: 0;
}

/**Componente qoxecha banner**/
.banner{
    background-color: #ddddda;
    padding: 25px 75px;
}

.wrapper_banner_logo img{
    width: 33%;
}

.wrapper_banner_contacto{
    margin-top: 6px;
    width: 40%;
    margin-left: 60%;
    text-align: end;
}

.wrapper_banner_contacto_telefono{
    float: left;
    padding-right: 10px;
    padding-top: 5px;
}

.wrapper_banner_contacto_telefono img{
    width: 28px;
    height: 28px;
}

.wrapper_banner_contacto_texto p{
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}

.wrapper_banner_contacto_texto .telefono{
    color: #232932;
}

.wrapper_banner_contacto_texto .correo{
    color: #9692a0;
}

/**Componente qoxecha navegador**/
.navegador{
    background-color: #88b12d;
    padding: 4px 75px;
    height: 84px;
}

.navegador .nav{
    -webkit-flex-wrap: inherit;
            flex-wrap: inherit;
}

.navegador .navbar-expand-lg .navbar-nav{
    width: 100%;
    padding-left: 101.35px;
}

.navegador .nav-item{
    cursor: pointer;
    padding-top: 0;
    -webkit-align-self: center;
            align-self: center;
    width: 16.67%;
}

.navegador .nav-item a{
    color: #ffffff;
    margin-bottom: 0;
    text-align: center;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

.navegador .nav-item a.borde{
    border-right: 1px dashed #ffffff;
}

.navegador .nav-item a.active{
    color: #d6d5c3;
}

.navegador .dropdown-menu{
    position: absolute;
    width: 400px;
    transition: all .3s ease;
    padding-left: 0;
    -webkit-box-shadow: 0 2px 10px 0 rgb(0 0 0);
}

.navegador .dropdown-menu.servicios{
    left: 420px;
}

.navegador .dropdown-menu.proyectos{
    left: 620px;
}

.navegador .dropdown-menu.galeria{
    left: 820px;
    padding: 0;
}

.dropdown-item.active, .dropdown-item:active{
    background-color: transparent;
}

.navegador .dropdown-menu a{
    color: #242424;
    font: 600 16px 'Open Sans', sans-serif;
    padding: 15px 20px 15px;
    border-bottom: 1px solid #e9e9e9;
    position: relative;
    display: block;
    text-transform: capitalize;
    text-decoration: none;
}
/**Componente board slider**/
.wrapper_slider_info_titulo{
    position: absolute;
    display: block;
    visibility: visible;
    left: 350px;
    top: 250px;
    z-index: 5;
}

.wrapper_slider_titulo.primero{
    padding-left: 50%;
}

.wrapper_slider_titulo p{
    z-index: 6;
    white-space: nowrap;
    font-size: 40px;
    line-height: 22px;
    font-weight: 700;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: inherit;
    border-width: 0px;
    margin: 0px 0px 5px 0px;
    padding: 0px;
    letter-spacing: 0px;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    opacity: 1;
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -webkit-transform-origin: 50% 50% 0px;
            transform-origin: 50% 50% 0px;
}

.wrapper_slider_descripcion{
    position: absolute;
    display: block;
    visibility: visible;
    left: 565px;
    top: 204px;
    z-index: 5;
}

.wrapper_slider_descripcion p{
    transition: none 0s ease 0s;
    text-align: inherit;
    line-height: 22px;
    border-width: 0px;
    margin: 15px 0px 0px 0px;
    padding: 0 75px 0 0;
    letter-spacing: 0px;
    font-weight: 400;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    color: #ffffff;
    font-style: italic;
}

.wrapper_slider_info_button{
    position: absolute;
    display: block;
    visibility: visible;
    left: 565px;
    top: 304px;
    z-index: 5;
}

.wrapper_slider_info_button{
    z-index: 10;
    white-space: nowrap;
    font-size: 16px !important;
    line-height: 48px !important;
    font-weight: 700;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: left;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    min-width: 0px;
    min-height: 0px;
    max-height: none;
    max-width: none;
    opacity: 1;
    -webkit-transform-origin:  50% 50% 0px;
            transform-origin:  50% 50% 0px;
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
}

.wrapper_slider_info_button p{
    background: #88b12d;
    color: #ffffff;
    transition: none 0s ease 0s;
    text-align: inherit;
    border-width: 1px;
    margin: 0px 20px 0 px 0px;
    letter-spacing: 0px;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    border-radius: 4px;
    padding: 0 40px !important;
}

/**Componente board servicios**/
.servicios{
    padding: 0 75px 35px 75px;
    background: #f5f4f4;
}

.wrapper_servicios_titulo{
    width: 32%;
    float: left;
}

.wrapper_servicios_descripcion{
    padding: 77px 0;
}

.wrapper_servicios_descripcion h2{
    font-family: 'Montserrat', sans-serif;
    text-align: right;
    font-weight: 600;
    border-right: 4px solid #88b12d;
    padding-right: 0.8em;
    margin-bottom: 0;
    font-size: 28px;
    line-height: 1.2em;
}

.wrapper_servicios_texto{
    width: 68%;
    font-size: 14px;
    padding-right: 1.5em;
    -webkit-align-self: center;
            align-self: center;
}

.wrapper_servicios_texto p{
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0;
    padding-left: 2em;
    font-style: italic;
    font-size: 1.2em;
    text-align: justify;
    font-weight: 400;
    line-height: 1.3em;
}

/**Componente board card servicio**/
.wrapper_servicios_cards{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.wrapper_servicios_cards .col-sm-12{
    padding-right: 20px;
    padding-left: 20px;
}

.card.servicio{
    border: none;
    background-color: #f5f4f4;
}

.card.servicio .card-body{
    padding: 0;
}

.card.servicio .wrapper_card_img img {
    -webkit-transition:all .9s ease; /* Safari y Chrome */
    -moz-transition:all .9s ease; /* Firefox */
    -o-transition:all .9s ease; /* IE 9 */
    -ms-transition:all .9s ease; /* Opera */
    width:100%;
}
.card.servicio .wrapper_card_img:hover img {
    -webkit-transform:scale(1.25);
    transform:scale(1.25);
}
.card.servicio .wrapper_card_img {/*Ancho y altura son modificables al requerimiento de cada uno*/
    width: 100%;
    overflow:hidden;
}

.card.servicio h4{
    border-bottom: 2px solid #88b12d;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 18px;
    padding: 0 0 15px 0;
    margin-top: 20px;
    margin-bottom: 10px;
    transition: all 300ms linear 0s;
    cursor: pointer;
}

.card.servicio h4:hover{
    color: #898989;
}

.card.servicio .card-text{
    color: #898989;
    font: 400 1.1em/1.6em "Open Sans", sans-serif;
    margin: 0;
    padding: 1em 0 0.5em;
    letter-spacing: 0.2px;
    font-size: 14px;
    text-align: justify;
}

.card.servicio h6{
    transition: all 300ms linear 0s;
    padding: 0 0 3em;
    margin: 10 0;
    padding: 0 0 35px 0;
}

.card.servicio a{
    background: #f5f4f4;
    border: none;
    color: #88b12d;
    font: 13px "Open Sans", sans-serif;
    font-weight: bold;
    padding: 6px 0;
    transition: all 300ms linear 0s;
}


.card.servicio a:hover{
    color: #898989;
    text-decoration: none;
}

/**Componente board nosotros**/
.nosotros{
    padding: 80px 75px 60px 75px;
}

.wrapper_nosotros_titulo{
    padding: 0 0 50px;
}

.wrapper_nosotros_titulo h2{
    color: #242424;
    font: 800 28px 'Montserrat', sans-serif;
    padding-bottom: 20px;
    margin: 0;
    letter-spacing: 0.3px;
}

.wrapper_nosotros_titulo .separador{
    height: 2px;
    border-bottom: 2px solid #88b12d;
    width: 80px;
}

.wrapper_nosotros_descripcion h4{
    color: #303030;
    font: 500 1.3em/1.5em 'Open Sans', sans-serif;
    margin: 0 0 1em;
    padding: 0;
    text-align: justify;
}

.wrapper_nosotros_descripcion strong a{
    font-size: 18px;
    color: #88b12d;
    cursor: pointer;
    font-weight: 700;
}

/**Componente board instalamos**/
.instalamos{
    padding: 80px 0 60px 0;
}

.wrapper_instalamos_titulo{
    margin: 0 75px;
    padding-bottom: 50px;
}

.wrapper_instalamos_titulo h2{
    color: #ffffff;
    font: 800 28px 'Montserrat', sans-serif;
    padding-bottom: 20px;
    margin: 0;
    letter-spacing: 0.3px;
}

.wrapper_instalamos_titulo .separador{
    height: 2px;
    border-bottom: 2px solid #898989;
    width: 80px;
}

/**Componente board card instalamos**/

.card.instalacion{
    border: none;
    background-color: transparent;
    width: 14.28%;
    padding: 0 15px;
}

.card.instalacion .wrapper_card_img img {
    -webkit-transition:all .9s ease; /* Safari y Chrome */
    -moz-transition:all .9s ease; /* Firefox */
    -o-transition:all .9s ease; /* IE 9 */
    -ms-transition:all .9s ease; /* Opera */
    width:100%;
    border-radius: 5px;
}

.card.instalacion .wrapper_card_img:hover img {
    -webkit-transform:scale(1.25);
    transform:scale(1.25);
}

.card.instalacion .wrapper_card_img {
    width: 100%;
    overflow:hidden;
    position: relative;
}

.card.instalacion .wrapper_card_img:hover .wrapper_search{
    opacity: 1;
    transform: translateY(0, 95px);
    -webkit-transform: translate(0, 95px);
    -o-transform: translate(0, 95px); 
    -moz-transform: translate(0, 95px);
}

.card.instalacion .wrapper_search{
    display: block;
    position: absolute;
    top: -150%;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    z-index: 10000
}

.card.instalacion .wrapper_search_caption_table{
    display: table;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;;
}

.card.instalacion .wrapper_search_caption{
    display: table-cell;
    vertical-align: middle;
    transition: all 0.35s ease-in-out;
}

.card.instalacion .wrapper_search_image{
    display: inline-block;
    width: 66px;
    height: 66px;
    line-height: 66px;
    text-align: center;
    background-color: #88b12d;
    border-radius: 50%;
}

.card.instalacion .wrapper_search_image img{
    width: 22px;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

/**Componente board contacto**/

.contacto{
    padding: 80px 75px 60px 75px;
}

.wrapper_contacto_titulo{
    padding: 0 0 50px;
}

.wrapper_contacto_titulo h2{
    color: #242424;
    font: 800 28px 'Montserrat', sans-serif;
    padding-bottom: 20px;
    margin: 0;
    letter-spacing: 0.3px;
}

.wrapper_contacto_titulo .separador{
    height: 2px;
    border-bottom: 2px solid #88b12d;
    width: 80px;
}

.wrapper_contacto_formulario_borde{
    border: 1px solid #ebebeb;
    overflow: hidden;
    margin-top: 0;
}

.wrapper_contacto_formulario{
    border: 9px solid #f7f7f9;
    padding: 2em 1.5em;
    overflow: hidden;
}

.contacto .form-group{
    margin-bottom: 20px;
}

.contacto input{
    border-radius: 0;
    font: 1em/1em 'Open Sans', sans-serif;
    color: #555555;
    padding: 11px 20px;
    border: 1px solid #eaeaea;
    height: auto;
    box-shadow: none;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.contacto button{
    color: #ffffff;
    background: #88b12d;
    font: 600 15px 'Montserrat', sans-serif;
    display: inline-block;
    padding: 1em 1.52em;
    margin: 0;
    cursor: pointer;
}

.contacto button:hover{
    border: 1px solid #88b12d;
    background: #ffffff;
    border-radius: 4px;
    color: #88b12d;
}


/**Componente qoxecha footer*/
.footer{
    padding: 80px 75px 60px 75px;
}

.wrapper_footer_titulo{
    padding: 0 0 24px;
}

.wrapper_footer_titulo h4{
    color: #ffffff;
    font: 800 20px 'Montserrat', sans-serif;
    padding-bottom: 20px;
    margin: 0;
    letter-spacing: 0.3px;
}

.wrapper_footer_titulo .separador{
    height: 2px;
    border-bottom: 2px solid #88b12d;
    width: 80px;
}

.footer .wrapper_footer_logo{
    padding-right: 20px;
    position: relative;
    min-height: 1px;
}

.footer .wrapper_footer_logo img{
    width : 80%;
}

.footer .wrapper_footer_redes{
    margin-top: 2em;
    font-size: 15px;
    color: #627279;
}

.footer .wrapper_footer_social{
    padding: 10px 9px;
    display: inline-block;
}

.footer .wrapper_footer_social img{
    width: 23px;
    height: 23px;
}

.footer .wrapper_footer_instalamos li,
.footer .wrapper_footer_menu li,
.footer .wrapper_footer_contacto li{
    background: transparent;
    border: none;
    color: #627279;
    font-family: "Open Sans", sans-serif;
    line-height: 39px;
    text-decoration: none;
    font-size: 15px;
    letter-spacing: 0.2px;
    cursor: pointer;
    transition: all 300ms linear 0s;
    padding: 0;
    display: list-item;
    font-weight: 600;
    list-style: none;
}

.footer .wrapper_footer_menu li a{
    color: #627279;
}

.footer .wrapper_footer_menu li a:hover{
    color: #88b12d;
    text-decoration: none;
}

.footer .wrapper_footer_contacto li{
    list-style: none;
    float: left; 
    width: calc(100% - 34px);
    font: 600 1em/1.4em 'Open Sans', sans-serif;
    padding: 0;
    line-height: 39px;
    font-size: 15px;
    vertical-align: middle;
    margin-bottom: 0;
}

.footer .wrapper_footer_contacto li img{
    width: 25px;
    height: 25px;
    float: left;
    margin-right: 9px;
    margin-top: 7px;
}


.footer .wrapper_footer_instalamos li:hover,
.footer .wrapper_footer_menu li:hover,
.footer .wrapper_footer_contacto li:hover{
    color: #88b12d;
}

.footer .wrapper_footer_instalamos .wrapper_footer_listado{
    padding-left: 15px;
}

.footer .wrapper_footer_menu .wrapper_footer_listado{
    padding-left: 8px;
}

/** componente qosecha copy right**/
.footer-developer{
    padding: 33px 75px 33px 75px;
    background: #06121c;
}

.footer-developer .wrapper_copy_right{
    color: #ffffff;
    padding: 0.5em 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

/**Componente galería imágenes y vídeos**/
.galeria{
    padding: 80px 75px 60px 75px;
}

.galeria .wrapper_galeria_content{
    position: relative;
    left: -89.5px;
    box-sizing: border-box;
    padding-left: 89.5px;
    padding-right: 89.5px;
}

.galeria_titulo_content{
    padding-left: 0;
    padding-right: 0;
}

.galeria_titulo{
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.galeria_titulo_header_content{
    position: relative;
    padding: 0 0 50px;
    margin-bottom: 0;
}

.galeria .heading_title{
    font: 600 28px 'Montserrat', sans-serif;
    color: #242424;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 20px;
    letter-spacing: 0.3px;
    margin: 0;
}

.galeria .heading_title:before{
    background-color: #88b12d;
    content: '';
    width: 55px;
    height: 3px;
    display: inline-block;
    background: #88b12d;
    position: absolute;
    bottom: 0;
}

.galeria.imagenes .imagenes_contenido{
    margin-left: -75px;
    margin-right: -75px;
    background: #303030;
}

.galeria.videos .imagenes_contenido{
    margin-left: -75px;
    margin-right: -75px;
    background: transparent;
}

.wrapper_galeria_imagenes{
    position: relative;
    box-sizing: border-box;
}

.wrapper_galeria_imagenes .imagen_fullscreen{
    width: 20%;
    padding: 0;
    margin: 0;
    float: left;
    position: relative;
}

.wrapper_galeria_imagenes .video_fullscreen{
    width: 100%;
    padding: 0;
    margin: 0;
    float: left;
    position: relative;
}

.wrapper_galeria_imagenes img{
    width: 100%;
    position: relative;
    transition: all .3s ease-out;
    width: 276px;
    height: 209px
}

.wrapper_galeria_imagenes .video_fullscreen video{
    width: 100%;
    position: relative;
    transition: all .3s ease-out;
    width: 552px;
    height: 418px
}

.galeria .wrapper_galeria{
    padding-bottom: 42px;
}

.video_image_hover{
    text-align: center;
}

/**Componente acerca de nosotros**/
.nosotros{
    padding: 80px 75px 60px 75px;
}

.wrapper_entry_content{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 1 1;
            flex: 1 1;
    min-width: 0;
}

.nosotros .wrapper_heading{
    padding: 0 0 50px;
}

.nosotros .wrapper_sub_heading{
    padding: 0 0 10px;
}

.nosotros .wrapper_heading h2,
.nosotros .wrapper_sub_heading h2{
    font: 600 28px 'Montserrat', sans-serif;
    color: #242424;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 20px;
    letter-spacing: 0.3;
    margin: 0;
}

.nosotros .wrapper_heading h2:before{
    content: '';
    width: 55px;
    height: 3px;
    display: inline-block;
    background: #88b12d;
    position: absolute;
    bottom: 0;
}

.nosotros .wrapper_sub_heading h2:before{
    content: '';
    width: 55px;
    height: 1px;
    display: inline-block;
    background: #88b12d;
    position: absolute;
    bottom: 0;
}

.nosotros .wrapper_descripcion p{
    color: #8e8d8d;
    margin: 0;
    font: 400 1em/1.8em 'Open Sans', sans-serif;
    text-align: justify;
}


.nosotros .wrapper_descripcion p strong{
    color: #242424;
}

.nosotros .wrapper_imagenes{
    padding-top: 28px;
    margin-left: -15px;
    margin-right: -15px;
}

.nosotros .wrapper_imagenes img{
    width: 100%;
}

/**MEDIA SCREENS**/
@media (min-width: 1199px){ /**992px -1199px**/
    .wrapper_banner_logo{
        padding-top: 6px;
    }
    .wrapper_banner_contacto{
        margin-top: 0;
        width: 55%;
        margin-left: 45%;
    }

    .navegador .nav-item{
        padding-top: 4px;
        -webkit-align-self: center;
                align-self: center;
    }

    .banner{
        padding: 25px 50px;
    }

    .navegador{
        padding: 0 50px;
    }

    .servicios{
        padding: 0 50px 35px 50px;
    }

    .wrapper_servicios_descripcion h2{
        text-align: justify;
    }

    .nosotros{
        padding: 80px 50px 60px 50px;
    }

    .contacto{
        padding: 80px 50px 60px 50px;
    }

    .footer{
        padding: 80px 50px 60px 50px;
    }

    .footer-developer{
        padding: 33px 50px 33px 50px;
    }
}


@media (min-width: 992px) and (max-width: 1198px){/**768 -991px**/
    .wrapper_banner_contacto{
        width: 70%;
        margin-left: 30%;
    }

    .card.instalacion{
        width: 25%;
    }

    .banner{
        padding: 25px ;
    }

    .navegador{
        padding: 0 25px;
    }

    .servicios{
        padding: 0 25px 35px 25px;
    }

    .nosotros{
        padding: 80px 25px 60px 25px;
    }

    .contacto{
        padding: 80px 25px 60px 25px;
    }

    .wrapper_nosotros_titulo h2{
        font: 800 22px 'Montserrat', sans-serif;
    }

    .wrapper_nosotros_descripcion h4{
        font: 500 1.1em/1.5em 'Open Sans', sans-serif;
    }

    .card.instalacion{
        width: 25%;
        margin-bottom: 15px;
    }

    .footer{
        padding: 80px 25px 60px 25px;
    }

    .footer .col-md-6{
        margin-bottom: 15px;
    }

    .footer-developer{
        padding: 33px 25px 33px 25px;
    }

    .wrapper_galeria_imagenes .imagen_fullscreen{
        width: 25%;
    }

    .navegador .dropdown-menu.servicios{
        left: 310px;
    }

    .navegador .dropdown-menu.proyectos{
        left: 460px;
    }

    .navegador .dropdown-menu.galeria{
        left: 610px;
        padding: 0;
    }
}


@media (min-width: 768px) and (max-width: 991px){ /**576px - 767px**/
    .wrapper_banner_logo{
        padding-top: 0;
    }

    .wrapper_banner_logo img{
        width: 100%;
    }

    .navegador .nav{
        padding-top: 26px;
    }

    .navegador .dropdown-menu{
        position: inherit;
    }

    .navegador .navbar-expand-lg .navbar-nav{
        background: #ffffff;
        z-index: 99999;
    }

    .navegador .nav-item a{
        color: #242424;
    }

    .navegador .navbar-toggler img{
        width: 36px;
    }

    .wrapper_slider_info_titulo{
        left: 200px;
    }

    .wrapper_banner_contacto{
        width: 75%;
        margin-left: 25%;
        padding-top: 6px;
    }

    .wrapper_servicios_titulo{
        width: 100%;
        float: none;
        margin-bottom: 15px;
    }

    .wrapper_servicios_texto{
        width: 100%;
        padding-right: 0;
    }

    .wrapper_servicios_texto p{
        padding-left: 0;
    }

    .card.instalacion{
        width: 33.33%;
        margin-bottom: 16px;
    }

    .wrapper_galeria_imagenes .imagen_fullscreen{
        width: 33.33%;
    }
}


@media (min-width: 576px) and (max-width: 767px) { /**0 - 575px**/
    .wrapper_banner_logo{
        padding-top: 8px;
    }

    .wrapper_banner_logo img{
        width: 80%;
        margin-left: 10%;
        margin-bottom: 16px;
    }

    .navegador .navbar-expand-lg .navbar-nav{
        background: #ffffff;
        z-index: 99999;
    }

    .navegador .nav-item a{
        color: #242424;
    }

    .banner .col-sm-4{
        -webkit-align-self: center;
                align-self: center;
    }

    .navegador .navbar-toggler img{
        width: 36px;
    }

    .wrapper_slider_info_titulo{
        left: 100px;
        top: 275px;
    }

    .wrapper_slider_titulo p{
        font-size: 30px;
        line-height: 10px;
    }

    .card.instalacion{
        width: 50%;
        margin-bottom: 16px;
    }

    .footer{
        text-align: center;
    }

    .wrapper_galeria_imagenes .imagen_fullscreen{
        width: 50%;
    }

    .navegador .dropdown-menu{
        position: inherit;
    }
}

@media (min-width: 0px) and (max-width: 575px){

    .wrapper_banner_logo img{
        width: 80%;
        margin-left: 10%;
        margin-bottom: 16px;
    }

    .navegador .navbar-expand-lg .navbar-nav{
        background: #ffffff;
        z-index: 99999;
    }

    .navegador .dropdown-menu{
        position: inherit;
    }

    .navegador .nav-item a{
        color: #242424;
    }

    .navegador .navbar-toggler img{
        width: 36px;
    }

    .wrapper_slider_info_titulo{
        left: 50px;
        top: 280px;
    }

    .wrapper_slider_titulo p{
        font-size: 22px;
        line-height: 5px;
    }

    .card.instalacion{
        width: 75%;
        margin-left: 12.5%;
        margin-bottom: 16px;
    }

    .wrapper_galeria_imagenes .imagen_fullscreen{
        width: 80%;
        margin-left: 10%;
    }

    .wrapper_banner_contacto{
        width: 100%;
        margin-left: 0;
    }

    .servicios{
        padding: 0 30px 35px 30px;
    }

    .nosotros{
        padding: 80px 30px 60px 30px;
    }

    .contacto{
        padding: 80px 30px 60px 30px;
    }

    .footer{
        padding: 80px 30px 60px 30px;
    }

    .banner{
        padding: 25px 30px;
    }

    .navegador{
        padding: 8px 30px;
    }

    .navegador .nav-item{
        -webkit-align-self: start;
                align-self: start;
        padding-left: 16px;;
    }
}
